import http from "../http/http"

// 形象仓库列表
export const housePageList = (params) => {
    return http({
        url: "/websiteBrand/queryWebImageWarehousePage",
        method: "post",
        data: params
    })
}

// 形象仓库详情
export const houseItemInfo = (params) => {
    return http({
        url: "/websiteBrand/queryImageWarehouseInfo",
        method: "post",
        data: params
    })
}

// 根据品牌ID查询形象仓库详情
export const houseItemInfoByBrand = (params) => {
    return http({
        url: "/websiteBrand/queryImageWarehouseInfoByBrand",
        method: "post",
        data: params
    })
}