<template>
  <div class="icon-list">
    <div class="icon-title-icon">
      <img src="../../static/img/brand/title_icon.png" class="icon">
      <template v-if="$store.state.language">
        <img src="../../static/img/storeHouse/title_CH.png" alt="" class="text-icon">
      </template>
      <template v-else>
        <img src="../../static/img/storeHouse/title_EN.png" alt="" class="text-icon">
      </template>
      <img src="../../static/img/brand/title_icon.png" class="icon ic-r">
    </div>
    <div class="search">
      <searchBox :areaCode.sync="areaCode" :findStr.sync="findStr"></searchBox>
    </div>
    <div class="icon-info-list">
      <!-- <div class="icon-info-item" v-for="(item,index) in list" :key="index" @click="goItem(item,index)">
        <div style="overflow: hidden;flex: 1;">
          <div class="info-img-box" @mouseenter="mouseenter(item,index)" @mouseleave="mouseLeave(item,index)" :class="{'item-active':item.checked}">
            <img :src="item.logo" class="icon-img">
          </div>
        </div>
        <div class="info-content">
          <div class="info-title">{{$store.state.language ? item.brandName : item.brandNameE}}</div>
          <div class="info-text">{{$store.state.language ? item.imageExplain : item.imageExplainE}}</div>
        </div>
      </div> -->
      <template v-for="(item,index) in list">
        <imageItem :info="item" :key="index"></imageItem>
      </template>
    </div>
    <div class="pagination">
      <el-pagination :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { housePageList } from '@/api/house'
import searchBox from '@/components/searchBox/searchBox'
import imageItem from '@/components/image-item/image-item'

export default {
  name: 'iconList',
  components: {
    searchBox,
    imageItem
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 20,
      total: 0,
      list: [],
      areaCode: '',
      findStr: ''
    }
  },
  watch: {
    areaCode(val) {
      this.getPageList()
    },
    findStr(val) {
      this.getPageList()
    }
  },
  created() {
    this.getPageList()
  },
  methods: {
    //  鼠标移入图片放大
    mouseenter(item, index) {
      item.checked = true
      this.$set(this.list, index, item)
    },
    // 鼠标移出图片
    mouseLeave(item, index) {
      item.checked = false
      this.$set(this.list, index, item)
    },
    goItem(item, index) {
      item.checked = false
      this.$set(this.list, index, item)
      this.$router.push({
        path: '/iconStoreHouse/iconItem',
        query: {
          warehouseId: item.warehouseId
        }
      })
    },
    // 获取列表
    getPageList() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        findStr: this.findStr,
        areaCode: this.areaCode
      }
      housePageList(params).then((res) => {
        if (res.code === 10000) {
          // console.log(res.data)
          let data = res.data
          this.total = data.total
          this.list = data.list
        }
      })
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getPageList()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="scss">
.icon-list {
  width: 1140px;
  margin: 40px auto 0;

  .icon-title-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: 468px;
      height: 28px;
    }
    .ic-r {
      transform: rotateY(180deg);
    }
    .text-icon {
      // width: 186px;
      height: 48px;
    }
  }
  .search {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 0 0;
  }
  .icon-info-list {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 0;
    .icon-info-item {
      cursor: pointer;
      width: 268px;
      height: 253px;
      background: url('../../static/img/storeHouse/item_info_bg.png') no-repeat
        left bottom #f1f1f1;
      background-size: 100% 96px;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      margin: 0 22px 20px 0;
      display: flex;
      flex-direction: column;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .info-img-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        transition: all 1s cubic-bezier(0, 1, 1, 1);
        .icon-img {
          height: 157px;
        }
      }
      .item-active {
        transform: scale(1.2);
        transform-origin: center center;
      }
      .info-content {
        height: 96px;
        .info-title {
          padding: 12px 15px 8px;
          font-size: 14px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: bold;
        }
        .info-text {
          padding: 0 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          // text-indent: 20px;
          background: url('../../static/img/home/indent-icon.png') no-repeat
            left 15px top 5px;
          background-size: 11px 10px;
        }
      }
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
    .el-pager {
      font-weight: initial;
      .number {
        font-size: 15px;
        color: #595757;
        padding: initial;
        min-width: 30px;
        height: 30px;
        margin: 0 5px;
      }
      .active {
        color: #ffffff;
        background: #843322;
        border-radius: 50%;
      }
    }
    .btn-prev {
      transform: rotateY(180deg);
    }
    .btn-prev,
    .btn-next {
      height: 30px;
      background: url('../../static/img/brand/page_icon.png') no-repeat center;
      background-size: 18px 30px;
      .el-icon {
        display: none;
      }
    }
  }
}
</style>