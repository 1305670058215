<template>
  <div class="image-item" @click="goItem(itemInfo)">
    <div style="overflow: hidden;flex: 1;">
      <div class="info-img-box" @mouseenter="mouseenter(itemInfo)" @mouseleave="mouseLeave(itemInfo)" :class="{'item-active':itemInfo.checked}">
        <img :src="itemInfo.logo" class="icon-img">
      </div>
    </div>
    <div class="info-content">
      <div class="info-title">{{$store.state.language ? itemInfo.brandName : itemInfo.brandNameE}}</div>
      <div class="info-text" :class="{'t-indent':$store.state.language}">{{$store.state.language ? itemInfo.imageExplain : itemInfo.imageExplainE}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    itemInfo() {
      this.info.imageExplain = this.info.imageExplain.replace('品牌标志', '')
      this.info.imageExplain = this.info.imageExplain.replace('LOGO', '')
      this.info.imageExplainE = this.info.imageExplainE.replace('Logo', '')
      //   this.info.checked = false
      return this.info
    }
  },
  data() {
    return {}
  },

  methods: {
    //  鼠标移入图片放大
    mouseenter(item) {
      //   item.checked = true
      this.$set(item, 'checked', true)
    },
    // 鼠标移出图片
    mouseLeave(item) {
      //   item.checked = false
      this.$set(item, 'checked', false)
    },
    goItem(item) {
      //   item.checked = false
      this.$set(item, 'checked', false)
      this.$router.push({
        path: '/iconStoreHouse/iconItem',
        query: {
          warehouseId: item.warehouseId
        }
      })
    }
  }
}
</script>

<style lang="scss">
.image-item {
  cursor: pointer;
  width: 268px;
  height: 253px;
  background: url('../../static/img/storeHouse/item_info_bg.png') no-repeat left
    bottom #f1f1f1;
  background-size: 100% 96px;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  margin: 0 22px 20px 0;
  display: flex;
  flex-direction: column;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .info-img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    transition: all 1s cubic-bezier(0, 1, 1, 1);
    .icon-img {
      height: 157px;
    }
  }
  .item-active {
    transform: scale(1.2);
    transform-origin: center center;
  }
  .info-content {
    height: 96px;
    .info-title {
      padding: 12px 15px 8px;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: bold;
    }
    .info-text {
      padding: 0 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      // text-indent: 20px;
      background: url('../../static/img/home/indent-icon.png') no-repeat left
        15px top 5px;
      background-size: 11px 10px;
    }
  }
  .t-indent {
    text-indent: 28px;
  }
}
</style>